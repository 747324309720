// src/pages/contact.js
import * as React from 'react';
import AboutUs from '../components/AboutUs/AboutUs';
import Breadcrumb from '../components/Breadcrumb/Breadcrumb';
import TextImageSection from '../components/TextImageSection/textImageSection';
import Layout from '../components/layout';
import Seo from '../components/seo';

const AboutPage = ({ data }) => {
  return (
    <Layout>
      <TextImageSection
        subtitle="About"
        title="企業概要"
        text="弊社はホームページを制作するだけでなく、運用までを含めたトータルサービスを得意としています。新規制作、リニューアルともにご相談を承ります。どうぞお気軽にご連絡ください。"
        imageUrl="/images/haikei3.jpg"
        imageAlt="ホームページ制作"
      />
      <Breadcrumb
        crumbs={[
          { pathname: '/', crumbLabel: 'TOP' },
          { pathname: '/about', crumbLabel: '企業概要' },
        ]}
      />
      <AboutUs
        imageSrc="/corporate/img-business.webp"
        imageAlt="Example image"
        title="案内"
        subTitle="OUTLINE"
        sectionId="outline"
        items={[
          {
            title: '社名',
            description: 'デジタルクリエイティブ株式会社',
          },
          {
            title: '設立',
            description: '2024年1月4日',
          },
          {
            title: '所在地',
            description:
              '〒192-0904 \n 東京都八王子市子安町4丁目23-8 坂本ビル202',
          },
          {
            title: '法人番号',
            description: '3010101015959',
          },
          {
            title: '代表取締役',
            description: '中野智貴',
          },
        ]}
      />
    </Layout>
  );
};

export default AboutPage;

export const Head = () => (
  <Seo
    title="会社概要"
    description="デジタルクリエイティブ株式会社の会社概要はこちら。法人番号は3010101015959です。"
    cover="cover/cover-about.jpg"
  />
);
